<template>
<div class="wrap h-box-shadow">
  <el-form class="fm" :model="model" label-width="205px" v-loading="loading">
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form-item>
          <template slot="label">{{$t('model.sample.Sample_Name')}}：</template>
          {{model.Sample_Name}}
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item>
          <template slot="label">{{$t('model.sample.Continent_or_Ocean')}}：</template>
          {{model.Continent_or_Ocean | dictMap(dict.tree_Continent)}}
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item>
          <template slot="label">{{$t('model.sample.Country')}}：</template>
          {{model.Country | dictMap(dict.tree_Continent)}}
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item>
          <template slot="label">{{$t('model.sample.Terrane')}}：</template>
          {{model.Terrane}}
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item>
          <template slot="label">{{$t('model.sample.Rock_or_stratigraphic_unit')}}：</template>
          {{model.Rock_or_stratigraphic_unit}}
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item>
          <template slot="label">{{$t('model.sample.Object_or_mineral_determined')}}：</template>
          {{model.Object_or_mineral_determined}}
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item>
          <template slot="label">{{$t('model.sample.Geodynamic_settings')}}：</template>
          {{model.Geodynamic_settings}}
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item>
          <template slot="label">{{$t('model.sample.Geological_period')}}：</template>
          {{model.Geological_period}}
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item>
          <template slot="label">{{$t('model.sample.Lithology')}}：</template>
          {{model.Lithology}}
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="变质级别">
          <template slot="label">{{$t('model.sample.Metamorphic_grade')}}：</template>
          {{model.Metamorphic_grade}}
        </el-form-item>
      </el-col>
      <el-col :span="24">
        <el-form-item label="刊物名">
          <template slot="label">{{$t('model.sample.Journal')}}：</template>
          {{model.Journal}}
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="题名">
          <template slot="label">{{$t('model.sample.Title')}}：</template>
          {{model.Title}}
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="第一作者名">
          <template slot="label">{{$t('model.sample.First_Author')}}：</template>
          {{model.First_Author}}
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="出版年">
          <template slot="label">{{$t('model.sample.Year')}}：</template>
          {{model.Year}}
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="测试机构">
          <template slot="label">{{$t('model.sample.Place_for_experiment')}}：</template>
          {{model.Place_for_experiment}}
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="测试仪器">
          <template slot="label">{{$t('model.sample.Instrument_for_analysis')}}：</template>
          {{model.Instrument_for_analysis}}
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="测试内容">
          <template slot="label">{{$t('model.sample.Analytical_content')}}：</template>
          {{model.Analytical_content}}
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="表达方式">
          <template slot="label">{{$t('model.sample.Expression')}}：</template>
          {{model.Expression}}
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item label="年龄解释">
          <template slot="label">{{$t('model.sample.Interpretation')}}：</template>
          {{model.Interpretation}}
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item>
          <template slot="label">{{$t('model.sample.Age')}}：</template>
          {{model.Age}}
        </el-form-item>
      </el-col>
      <el-col :span="12">
        <el-form-item>
          <template slot="label">{{$t('model.sample.SE2')}}：</template>
          {{model.SE2}}
        </el-form-item>
      </el-col>
    </el-row>
  </el-form>
</div>
</template>

<script>
import commonApi from '@/api/common'
import sampleApi from '@/api/dSample'
export default {
  name: 'Search_Detail_Sample',
  components: {
  },
  data() {
    return {
      loading: false,
      sampleId: this.$route.query.sampleId,
      dict: {
        tree_Continent: [], // 大陆大洋|国家
      },
      fm: {
        Continent_or_Ocean: '', // 大陆或大洋
        Country: '', // 国家
        Terrane: '', // 地体
        Rock_or_stratigraphic_unit: '', // 岩石或地层单元
        Object_or_mineral_determined: '', // 测定对象或矿物
        Geodynamic_settings: '', // 地球动力学背景
        Geological_period: '', // 地质时代
        Min_age_limit: '', // 地质时代最小值
        Max_age_limit: '', // 地质时代最大值
        Lithology: '', // 岩性
        Metamorphic_grade: '', // 变质级别
        Reference_type: '', // 文献类型
        Language: '', // 语言
        Journal: '', // 刊物名
        Title: '', // 题名
        First_Author: '', // 第一作者名
        Year: '', // 出版年
        Place_for_experiment: '', // 测试机构
        Instrument_for_analysis: '', // 测试仪器
        Analytical_content: '', // 测试内容
        Expression: '', // 表达方式
        Interpretation: '', // 年龄解释
        Age_begin: '', // 年龄值
        Age_end: '', // 年龄值
        SE2_begin: '', // 误差2SE(Ma)
        SE2_end: '', // 误差2SE(Ma)
      },
      model: {}
    }
  },
  methods: {
    async getDict() {
      // 大陆或大洋
      const dicts = await commonApi.getVueDictionary(['tree_Continent'])
      if (dicts && dicts.length) {
        this.dict.tree_Continent = dicts.find(item => item.dicNo === 'tree_Continent').data
      }
    },
    getData() {
      if (!this.sampleId) {return}
      this.loading = true
      sampleApi.getPageData({
        page: 1,
        rows: 10,
        wheres: `[
          {"name":"Sample_ID","value":"${this.sampleId}","displayType":"number"}
        ]`.replace(/\s+/g,"")
      }).then(res => {
        this.loading = false
        if (!res || !res.rows || !res.rows.length) {return}
        this.model = res.rows[0]
      }).catch(err => {
        this.loading = false
      })
    }
  },
  mounted() {
    this.getDict()
    this.getData()
  }
}
</script>

<style lang="less" scoped>
  .wrap {
    padding-top: 20px;
    background-color: #fff;
  }
  // .fm {
  //   /deep/ .el-col-12 {
  //     height: 60px;
  //   }
  // }
</style>
